import {RealmAppProvider, useRealmApp} from "./RealmApp";
import {BrowserRouter as Router} from "react-router-dom";
import Login from "./layout/login";
import RealmApolloProvider from "./apollo/RealmApolloProvider";
import Layout from "./layout"
import moment from "moment";
moment.locale("fr-fr");

const RequireLoggedInUser = ({children}) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();
  return app.currentUser ? children : <Login/>;
};

function App() {
  return (
    <RealmAppProvider appId={process.env.REACT_APP_REALM_ID}>
      <RequireLoggedInUser>
        <RealmApolloProvider>
          <Router>
            <Layout/>
          </Router>
        </RealmApolloProvider>
      </RequireLoggedInUser>
    </RealmAppProvider>
  );
}

export default App;
