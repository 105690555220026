import React from 'react';
import {Divider, Input, Space, Typography} from "antd";
import Sessions from "../sessions/sessions";
import {eventLocations} from "../../../../../../apollo/variables";
import Groups from "../groups/groups";

const {Text} = Typography
export default function Location(props) {
  const {id} = props
  
  function updateName(value) {
    const newLocation = {...eventLocations()[id]}
    newLocation.name = value
    eventLocations({...eventLocations(), [id]: newLocation})
  }
  
  return (
    <>
      <Space>
        <Text>
          Nom:
        </Text>
        <Input
          defaultValue={eventLocations()[id].name}
          onChange={(e) => setTimeout(() => updateName(e.target.value))}
          style={{width: "100%"}}
        />
      </Space>
      <Divider>
        Accès
      </Divider>
      <Sessions locationId={id}/>
      <Divider>
        Sessions
      </Divider>
      <Groups locationId={id}/>
    </>
  );
}