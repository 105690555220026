import {gql} from "@apollo/client"

const EVENTS = gql`
    query events($query:EventQueryInput){
        events(query:$query){
            _id
            title
            customer{
                name
            }
            provider{
                name
            }
            manager{
                firstName
                lastName
            }
        }
    }
`

const EVENT = gql`
    query event($query:EventQueryInput){
        event(query:$query){
            _id
            title
            provider{
                name
            }
            customer{
                _id
                name
            }
            manager{
                firstName
                lastName
            }
            start
            end
            nature
            description
            countStats
            sumStats{
                field
                chart
            }
        }
    }
`

export {EVENTS, EVENT}