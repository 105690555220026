import {makeVar} from "@apollo/client";
import {basicForm} from "../utils/constants";
import {v4 as uuidv4} from "uuid";
//event variables
const newEventId=makeVar(undefined)
const eventInfos = makeVar({})
const locationId = uuidv4()
const eventLocations = makeVar({
  [locationId]: {
    id: locationId,
    name: "Nouveau Lieu",
    type: "offline",
    sessions: {},
    groups: {}
  }
})
const eventForm = makeVar([...basicForm])
const eventOfflineLocations = makeVar([{
  id: uuidv4(),
  name: "Nouveau Lieu",
}])
const eventSessions = makeVar([])
const eventGroups = makeVar([])

export {
  eventInfos,
  eventLocations,
  eventForm,
  eventOfflineLocations,
  eventSessions,
  eventGroups,
  newEventId
};
