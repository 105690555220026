import React from 'react';
import {Button, Checkbox, Form, Input, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {eventLocations} from "../../../../../../apollo/variables";

export default function Session(props) {
  const {locationId, form, id,} = props
  const session = eventLocations()[locationId].sessions[id]
  
  function updateSession(values) {
    const newLocation = {...eventLocations()[locationId]}
    newLocation.sessions[id] = {
      ...session,
      [values[0].name[0]]: values[0].value
    }
    eventLocations({...eventLocations(), [locationId]: newLocation})
  }
  
  function deleteSession(id) {
    const newLocation = {...eventLocations()[locationId]}
    delete newLocation.sessions[id]
    Object.values(newLocation.groups).map(group => {
      if (group?.sessions?.[id]) {
        delete newLocation.groups[group.id].sessions[id]
      }
    })
    eventLocations({...eventLocations(), [locationId]: newLocation})
  }
  
  return (
    <div
      style={{marginTop: 5, marginBottom: 5}}
    >
      <Form
        form={form}
        layout="inline"
        initialValues={{...session}}
        onFieldsChange={updateSession}
      >
        <Form.Item label="Titre" name='title' style={{flexGrow: 1}}>
          <Input/>
        </Form.Item>
        <Form.Item name="repetitive" valuePropName="checked">
          <Checkbox>Répétetif</Checkbox>
        </Form.Item>
        <Form.Item>
          <Tooltip title="Supprimer">
            <Button
              type="link"
              size={"large"}
              icon={<DeleteOutlined/>}
              onClick={() => deleteSession(id)}
              danger
            />
          </Tooltip>
        </Form.Item>
      </Form>
    </div>
  );
}