import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import TagManager from 'react-gtm-module'

TagManager.initialize({
  gtmId: "GTM-TD6ML23"
})
ReactDOM.render(<App />, document.getElementById('root'));



