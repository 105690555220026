import React, {useState} from 'react';
import {Button, Layout, Menu, Typography} from "antd";
import {LogoutOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {routes} from "../utils/routes";
import {useRealmApp} from "../RealmApp";
import useLogin from "../hooks/login";

const {Header, Content, Sider} = Layout;
const {Title} = Typography;

export default function DesktopLayout(props) {
  const history = useHistory();
  const app = useRealmApp();
  const {handleLogout} = useLogin()
  const [collapsed, setCollapsed] = useState(false);
  
  function onMenuClick(params) {
    history.push(params.key);
  }
  
  return (
    <Layout>
      <Header
        style={{
          backgroundColor: "white",
          position: "fixed",
          zIndex: 2,
          width: "100%",
          boxShadow: "0px 3px 3px rgba(0,0,0,0.08)",
        }}
      >
        <Title style={{display: "inline-block", color: "black"}} level={4}>
          {app?.currentUser.customData.firstName + " " + app?.currentUser.customData.lastName}
        </Title>
        <div
          style={{
            display: "inline-block",
            lineHeight: "64px",
            float: "right",
            color: "#fff",
          }}
        >
          {/*<Link to={"/user/"}>*/}
          {/*  <Button type="link" size="large">*/}
          {/*    <UserOutlined style={{color: "black"}}/>*/}
          {/*  </Button>*/}
          {/*</Link>*/}
          
          <Button type="link" onClick={() => handleLogout()} size="large">
            <LogoutOutlined style={{color: "black"}}/>
          </Button>
        </div>
      </Header>
      <Layout style={{color: "#fff"}} hasSider>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(collapsed) => setCollapsed(collapsed)}
          width={230}
          style={{
            overflow: "auto",
            //paddingTop: 64,
            left: 0,
            height: "100%"
          }}
        >
          <Header/>
          <Menu
            mode="inline"
            onClick={onMenuClick}
            style={{
              maxWidth: 230,
              //position: "fixed",
              height: "100vh"
            }}
          >
            {routes.map((item) =>
              (
                <Menu.Item key={item.href}>
                  {item.icon}
                  <span>{item.label}</span>
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout style={{width: "100%"}}>
          <Content
            style={{
              marginTop: 64,
              height: "100%",
              padding: 10,
            }}
          >
            {/*<Breadcrumb>*/}
            {/*  <Breadcrumb.Item>*/}
            {/*    <Tooltip title="retour">*/}
            {/*      <Button*/}
            {/*        shape="circle"*/}
            {/*        icon={<ArrowLeftOutlined/>}*/}
            {/*        onClick={() => history.goBack()}*/}
            {/*      />*/}
            {/*    </Tooltip>*/}
            {/*  </Breadcrumb.Item>*/}
            {/*  <Breadcrumb.Item>*/}
            {/*    <UserOutlined/>*/}
            {/*    <span>Application List</span>*/}
            {/*  </Breadcrumb.Item>*/}
            {/*  <Breadcrumb.Item>Application</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}
            <div style={{backgroundColor:"white"}}>{props.children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}