import React, {useState} from 'react';
import {Button, Form, Modal, Space} from "antd";
import {EyeOutlined, PlusOutlined} from "@ant-design/icons"
import FormPreview from "./formPreview";
import {basicForm, newFormItem} from "../../../../../utils/constants";
import FormItem from "./formItem";

export default function EventForm(props) {
  const {form} = props
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <div
      style={{
        paddingLeft: 70,
        paddingRight: 70,
        paddingTop: 0,
      }}
    >
      <Form form={form}>
        <Form.List name="fields" initialValue={basicForm}>
          {(fields, {add, remove}) => (
            <div>
              {fields.map((field, i) => (
                <FormItem
                  key={i}
                  index={i}
                  field={field}
                  remove={remove}
                  type={form.getFieldValue("fields")[i].type}
                />
              ))}
              <Space direction="vertical" style={{width: "100%",marginBottom:20}}>
                <Button
                  onClick={() => add({...newFormItem})}
                  block
                  htmlType="submit"
                  type="primary"
                  size="large"
                  icon={<PlusOutlined/>}
                  style={{marginTop: 20}}
                >
                  Ajouter des champs
                </Button>
                <Button
                  block
                  size="large"
                  onClick={() => setIsModalVisible(true)}
                  icon={<EyeOutlined/>}
                >
                  Prévisualier
                </Button>
              </Space>
            </div>
          )}
        </Form.List>
      </Form>
      <Modal
        title="Aperçu du formulaire"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        centered
        width={800}
      >
        <FormPreview fields={form.getFieldValue("fields")}/>
      </Modal>
    </div>
  );
}