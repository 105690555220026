import {gql} from "@apollo/client"

const UPSERT_FORM = gql`
    mutation upsertOneForm($data:FormInsertInput!,$query:FormQueryInput){
        upsertedForm:upsertOneForm(data:$data,query:$query){
            _id
            fields{
                type
                label
                options
                required
            }
            event{
                _id
            }
        }
    }
`

export {UPSERT_FORM}