import React from 'react';
import {Button, Card, message, Typography} from "antd";
import {RightOutlined} from "@ant-design/icons";
import Locations from "./locations/locations";
import {
  eventForm,
  eventInfos,
  eventLocations
} from "../../../../../apollo/variables";
import {v4 as uuidv4} from 'uuid';
import moment from "moment";
import {useUpdateEventInfos} from "../../../../../hooks/events/event";
import {useParams} from "react-router-dom";

const {Title} = Typography

export default function Access(props) {
  const {goNextStep} = props
  const {updateEvent, loading} = useUpdateEventInfos()
  const {_id} = useParams()
  function goForward() {
    const locations = Object.values(eventLocations())
      .map(location => {
        return {
          ...location,
          sessions: Object.values(location.sessions),
          groups: Object.values(location.groups)
            .map(group => {
              return {
                ...group,
                sessions: Object.values(group.sessions).map(session=>({
                  ...session,
                  state:"open",
                  subscriptions:0,
                  date:session.date.toISOString()
                }))
              }
            })
        }
      })
    if (["mixed", "online"].includes(eventInfos().nature)) {
      const sessionId = uuidv4()
      locations.push({
        type: "online",
        name: "Online",
        id: uuidv4(),
        sessions: [{
          id: sessionId,
          title: "online",
          repeat: true
        }],
        groups: [{
          id: uuidv4(),
          title: "onlineGrp",
          sessions: [{
            id: sessionId,
            capacity: 1000000,
            date: moment.now().toISOString(),
            title: "online",
            repeat: true,
            subscriptions: 0,
            state: "open"
          }]
        }]
      })
    }
    updateEvent(_id, {locations})
      .then(() => {
        message.success("L'évènement a bien été mis-à-jour", 2)
        goNextStep()
      })
      .catch(e => {
        console.log(e)
        message.error("L'évènement n'a pas été mis-à-jour!", 2)
      })
  }
  
  return (
    <Card
      type="inner"
      title={
        <Title level={3} style={{marginBottom: 10}}>
          Lieux
        </Title>
      }
      actions={[
        <Button type="link" block onClick={() => goForward()} size="large" loading={loading}>
          Enregistrer & Continuer
          <RightOutlined/>
        </Button>,
      ]}
    >
      <Locations/>
    </Card>
  );
}