import {useRealmApp} from "../RealmApp";
import {useHistory} from "react-router-dom";
import * as Realm from "realm-web";
import {useState} from "react";

export default function useLogin() {
  
  const app = useRealmApp();
  const history = useHistory()
  const [error, setError] = useState(false);
  
  function handleLogin(email, password) {
    app.logIn(Realm.Credentials.emailPassword(email, password))
      .then(() => {
        history.push("/events")
      })
      .catch(() => setError(true))
  }
  
  function handleLogout() {
    app.logOut()
    history.push("/login")
  }
  
  return {
    handleLogin,
    handleLogout,
    error
  }
}