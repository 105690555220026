import React, {useEffect} from 'react';
import {DatePicker, Form, Input, Radio, Select} from "antd";
import moment from "moment";
import {useLazyCustomers} from "../../../../hooks/customers/customers";

const {TextArea} = Input
const {Option} = Select
const {RangePicker} = DatePicker;

export default function Infos(props) {
  const {isEditable, form, event} = props
  const {
    called,
    customers,
    loadCustomers,
    loading: customerLoading,
  } = useLazyCustomers()
  
  useEffect(() => {
    if (isEditable && !called) {
      loadCustomers()
    }
  }, [isEditable]);
  
  return (
    <Form
      form={form}
      labelCol={{xs: 24, sm: 2}}
      wrapperCol={{xs: 24, sm: 10}}
    >
      <Form.Item
        label="Client"
        name={"customer"}
        rules={[
          {
            required: isEditable === true,
            message: "Veuillez sélectionner un client!",
          },
        ]}
      >
        {!isEditable ?
          (<span>{event?.customer?.name}</span>) : (
            <Select
              showSearch
              loading={customerLoading}
              onClick={loadCustomers}
              placeholder="Selectionner un client"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {customers && customers.map((customer) => (
                <Option key={customer._id} value={customer._id}>
                  {customer.name}
                </Option>
              ))}
            </Select>
          )}
      </Form.Item>
      <Form.Item
        label={"Titre"}
        name={"title"}
        rules={[
          {
            required: isEditable === true,
            message: "Veuillez saisir le titre!",
          },
        ]}
      >
        {!isEditable ? (<span>{event?.title}</span>) : (
          <Input autoComplete="off"/>)}
      </Form.Item>
      <Form.Item
        label={"Description"}
        name={"description"}
        rules={[
          {
            required: isEditable === true,
            message: "Veuillez sélectionner un client!",
          },
        ]}
      >
        {!isEditable ? (<span>{event?.description}</span>) : (
          <TextArea rows={3} autoComplete="off"/>)}
      </Form.Item>
      <Form.Item
        label={"Date"}
        name={"date"}
        rules={[
          {
            required: isEditable === true,
            message: "Veuillez sélectionner la date!",
          },
        ]}
      >
        {!isEditable ? (
          <span>
                {moment(event?.start).format("dddd, Do MMMM YYYY à HH:mm")}{" "}
            à {" "}
            {moment(event?.end).format("dddd, Do MMMM YYYY à HH:mm")}
              </span>) : (
          <RangePicker
            showTime={{format: 'HH:mm'}}
            format="DD/MM/YYYY HH:mm"
          />
        )}
      </Form.Item>
      <Form.Item
        label="Nature"
        name="nature"
        rules={[
          {
            required: isEditable === true,
            message: "Veuillez sélectionner la nature!",
          },
        ]}
      >{!isEditable ? (<span>{event?.nature}</span>) : (
        <Radio.Group
          buttonStyle="solid"
        >
          <Radio.Button value={"offline"}>Présentiel</Radio.Button>
          <Radio.Button value={"online"}>Enligne</Radio.Button>
          <Radio.Button value={"hybride"}>Hybride</Radio.Button>
        </Radio.Group>
      )}
      </Form.Item>
    </Form>
  );
}