import React from 'react';
import {
  Checkbox,
  DatePicker,
  Form,
  Input, InputNumber,
  Radio,
  Select,
  TimePicker
} from "antd";

export default function  FormPreview({fields,form,initialValues}) {
  
  function getFieldComponent(field) {
    switch (field.type) {
      case "date":
        return <DatePicker format="DD/MM/YYYY"/>
      case "select":
        return <Select
          defaultValue={field.options[0]}
          options={field.options.map((value => ({label: value, value})))}
        />
      case "checkbox":
        return <Checkbox.Group options={field.options}/>
      case "time":
        return <TimePicker/>
      case "radio":
        return <Radio.Group defaultValue={field.options[0]}>
          {field.options.map((value, i) => (
            <Radio key={i} value={value}>{value}</Radio>))}
        </Radio.Group>
      case "number":
        return <InputNumber/>
      case "email":
        return <Input autoComplete="off" type="email"/>
      default:
        return <Input autoComplete="off"/>
    }
  }
  
  return (
    <Form labelCol={{xs: 24, sm: 5}} wrapperCol={{xs: 24, sm: 17}} form={form} initialValues={initialValues}>
      {fields.map((field, i) => (
        <Form.Item key={i} label={field.label} name={field.label} rules={[{required:field.required}]}>
          {getFieldComponent(field)}
        </Form.Item>
      ))}
    </Form>
  );
}