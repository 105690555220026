import {useMutation} from "@apollo/client";

function useUpdateOne(props) {
  const {refetch, mutation,update} = props
  const [updateOne, {loading}] = useMutation(mutation,{update})
  
  function updateItem(_id,set,optimisticResponse) {
    return updateOne({
      variables: {query: {_id}, set},
      optimisticResponse,
      //refetchQueries: refetch
    })
  }
  
  return {
    updateItem,
    loading
  }
}

export {useUpdateOne}