import React, {lazy, Suspense} from 'react';
import {Route, Switch} from "react-router-dom";
import {Skeleton} from "antd";
import NewEvent from "./events/events/newEvent";

//const Customer =lazy(() => import("./customers/customer"));
const Customers = lazy(() => import("./customers/customers"));
const Providers = lazy(() => import("./providers/providers"));
const Provider =lazy(() => import("./providers/provider"));
const Users=lazy(() => import("./users/users"));
const Events=lazy(() => import("./events/events/events"));
const NewEventSteps=lazy(() => import("./events/events/createEvent/steps"));
const Event=lazy(() => import("./events/events/event"));
const Registration=lazy(() => import("./events/events/viewEvent/registration"));

export default function Routes() {
  return (
    <Suspense fallback={<Skeleton active />} >
      <Switch>
        <Route path="/home">
          <div/>
        </Route>
        <Route path="/providers">
          <Providers/>
        </Route>
        <Route path="/provider/:_id">
          <Provider/>
        </Route>
        <Route path="/customers">
          <Customers/>
        </Route>
        {/*<Route path="/customer/:_id">*/}
        {/*  <Customer/>*/}
        {/*</Route>*/}
        <Route path="/users">
          <Users/>
        </Route>
        <Route path="/events">
          <Events/>
        </Route>
        <Route path="/event/:_id">
          <Event/>
        </Route>
        <Route path="/newEvent/:_id">
          <NewEvent/>
        </Route>
        <Route path="/inscription/:_id">
          <Registration/>
        </Route>
      </Switch>
    </Suspense>
  );
}