import {gql} from "@apollo/client"

const CUSTOMERS=gql`
  query customers($query:CustomerQueryInput){
      customers(query:$query){
          _id
          name
      }
  }
`

const CUSTOMER=gql`
query customer($query:CustomerQueryInput){
    customer(query:$query){
        _id
        name
        contacts{
            email
            name
            phone
        }
    }
}
`

export {CUSTOMERS,CUSTOMER}