import React from 'react';
import {Button, Checkbox, Form, Input, Radio} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";

export default function FormItemOptions(props) {
  const {field,type}=props
  function getLabel() {
    switch (type) {
      case "checkbox":
        return <Checkbox disabled/>
      case "radio":
        return <Radio disabled/>
      default:
        return undefined
    }
  }
  
  return (
      <Form.List
        name={[field.name, 'options']}
        fieldKey={[field.fieldKey, 'options']}
      >
        {(options,{add,remove})=>(
          <>
            {options.map((option,i)=>(
              <Form.Item
                colon={false}
                name={option.name}
                fieldKey={option.fieldKey}
                label={getLabel() ? getLabel() : i + 1}
                key={i}
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir une valeur!"
                  }
                ]}
                style={{width:"100%"}}
              >
                <Input
                  addonAfter={i > 1 ? (
                    <DeleteOutlined
                      onClick={() => remove(i)}
                    />
                  ) : null}
                />
               
              </Form.Item>
            ))}
            <Button
              style={{marginBottom:25}}
              type="dashed"
              onClick={() => add("")}
              block
              icon={<PlusOutlined/>}
            >
              Ajouter des choix
            </Button>
          </>
        )}
      </Form.List>
  );
}