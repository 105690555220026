import React from 'react';
import {Divider, Radio} from "antd";
import moment from "moment";

export default function SessionsPreview({groups}) {
  
  return (
    <>
      {groups.map(group => (
        <div style={{textAlign: "center"}}>
          <Divider>
            {group.title}
          </Divider>
          <Radio.Group buttonStyle="solid" style={{textAlign: "center"}}>
            {
              group.sessions.map((session, i) => (
                <Radio.Button
                  value={session.title}
                  key={i}
                  style={{height: "auto", textAlign: "center"}}
                >
                  {session.title}
                  <br/>
                  {moment(session.date).format("DD/MM/YYYY hh:mm")}
                </Radio.Button>
              ))
            }
          </Radio.Group>
        </div>
      ))}
    </>
  );
}