import {gql} from "@apollo/client"

const INSERT_EVENT = gql`
    mutation insertOneEvent($data:EventInsertInput!){
        insertedEvent:insertOneEvent(data:$data){
            _id
            title
        }
    }
`

const UPDATE_EVENT = gql`
    mutation updateOneEvent($query: EventQueryInput, $set: EventUpdateInput!){
        updatedEvent:updateOneEvent(query:$query,set:$set){
            _id
            title
            provider{
                name
            }
            customer{
                _id
                name
            }
            manager{
                firstName
                lastName
            }
            start
            end
            nature
            description
            countStats
            sumStats{
                field
                chart
            }
        }
    }
`

const DISABLE_EVENT = gql`
    mutation updateOneEvent($query: EventQueryInput, $set: EventUpdateInput!){
        updateOneEvent(query:$query,data:$data){
            _id
        }
    }
`
export {INSERT_EVENT, UPDATE_EVENT, DISABLE_EVENT}