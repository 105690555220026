import React, {useState} from 'react';
import {Button, Card, Modal, Tabs} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import SessionsPreview from "../sessions/sessionsPreview";
import Group from "./group";
import {eventLocations} from "../../../../../../apollo/variables";
import {v4 as uuidv4} from "uuid";
import {useReactiveVar} from "@apollo/client";

const {TabPane} = Tabs
export default function Groups(props) {
  const {locationId} = props
  const watchLocations = useReactiveVar(eventLocations)
  const groups = Object.values(watchLocations[locationId].groups)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  
  function addGroup() {
    const newLocation = {...watchLocations[locationId]}
    const id = uuidv4()
    newLocation.groups[id] = {
      id,
      title: "Nouveau Groupe",
      sessions: {}
    }
    eventLocations({...eventLocations(), [locationId]: newLocation})
    setActiveKey(id)
  }
  
  function removeGroup(id) {
    const newLocation = {...watchLocations[locationId]}
    delete newLocation.groups[id]
    eventLocations({...eventLocations(), [locationId]: newLocation})
  }
  
  function onEdit(targetKey, action) {
    if (action === "add") {
      addGroup()
    } else {
      removeGroup(targetKey)
    }
  }
  
  return (
    <Card>
      <Tabs
        type="editable-card"
        onEdit={onEdit}
        centered
        size="large"
        activeKey={activeKey}
        onTabClick={(key) => setActiveKey(key)}
      >
        {groups.map((group, i) => (
          <TabPane
            tab={group.title}
            closable={i > 0}
            key={group.id}
          >
            <Group
              id={group.id}
              locationId={locationId}
            />
          </TabPane>
        ))}
      </Tabs>
      {
        groups.length > 0 && (
          <Button
            block
            onClick={() => setIsModalVisible(true)}
            icon={<EyeOutlined/>}
            size="large"
          >
            Prévisualier
          </Button>
        )
      }
      <Modal
        title="Aperçu des sessions"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        centered
        width={800}
      >
        <SessionsPreview groups={groups}/>
      </Modal>
    </Card>
  );
}