import React from 'react';
import {PlusOutlined} from "@ant-design/icons";
import {Button, Card} from "antd";
import Session from "./session";
import {eventLocations} from "../../../../../../apollo/variables";
import {v4 as uuidv4} from 'uuid';
import {useReactiveVar} from "@apollo/client";

export default function Sessions(props) {
  const {locationId} = props
  const watchLocations = useReactiveVar(eventLocations)
  const sessions = Object.values(watchLocations[locationId].sessions)
  
  function addSession() {
    const newLocation = {...watchLocations[locationId]}
    const id = uuidv4()
    newLocation.sessions[id] = {
      id,
      title: "",
      repeat: false,
      isSelected: false
    }
    eventLocations({...eventLocations(), [locationId]: newLocation})
  }
  
  return (
    <Card>
      {sessions.map((session, i) => (
        <Session
          locationId={locationId}
          id={session.id}
          key={i}
        />
      ))}
      <Button
        onClick={() => addSession()}
        block
        type="primary"
        size="large"
        icon={<PlusOutlined/>}
        style={{marginBottom: 15}}
      >
        Ajouter Session
      </Button>
    </Card>
  );
}