import React, {useState} from 'react';
import {Tabs} from 'antd';
import Location from "./location";
import {useReactiveVar} from "@apollo/client";
import {eventLocations} from "../../../../../../apollo/variables";
import {v4 as uuidv4} from 'uuid';

const {TabPane} = Tabs;
export default function Locations() {
  
  const watchLocations = useReactiveVar(eventLocations)
  const [activeKey, setActiveKey] = useState(Object.keys(watchLocations)[0]);
  
  function onEdit(targetKey, action) {
    if (action === "add") {
      addTab()
    } else {
      removeTab(targetKey)
    }
  }
  
  function addTab() {
    let id = uuidv4()
    eventLocations({
      ...eventLocations(), [id]: {
        id,
        name: "Nouveau Lieu",
        type: "offline",
        sessions: {},
        groups: {}
      }
    })
    setActiveKey(id)
  }
  
  function removeTab(key) {
    const newLocations = {...eventLocations()}
    delete newLocations[key]
    eventLocations(newLocations)
  }
  
  return (
    <Tabs
      type="editable-card"
      onEdit={onEdit}
      centered
      activeKey={activeKey}
      onTabClick={(key) => setActiveKey(key)}
    >
      {Object.values(watchLocations)
        .filter(location => location.type === "offline")
        .map((location, i) => (
          <TabPane
            tab={location.name}
            closable={i > 0}
            key={location.id}
          >
            <Location id={location.id}/>
          </TabPane>
        ))}
    </Tabs>
  
  );
}