import React, {useState} from 'react';
import {Button, Checkbox, Col, Form, Input, Row, Select, Tooltip} from "antd";
import {
  AimOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  DownOutlined, FieldNumberOutlined,
  FontColorsOutlined, PhoneOutlined
} from "@ant-design/icons";
import FormItemOptions from "./formItemOptions";

const {Option} = Select

export default function FormItem(props) {
  const {index, remove, field,type} = props
 
  const [fieldType, setFieldType] = useState(type);
  
  function getField(type) {
    switch (type) {
      case "select":
      case "checkbox":
      case "radio":
        return <FormItemOptions type={type} field={field}/>
      default:
        return null
    }
  }
  
  return (
    <Row gutter={[10, 10]}>
      <Col xs={10}>
        <Form.Item
          name={[field.name, 'label']}
          fieldKey={[field.fieldKey, 'label']}
          rules={[
            {
              required: true,
              message: "Veuillez saisir le nom du champ!"
            }
          ]}
        >
          <Input
            autoComplete="off"
            placeholder='Nom du champ'
            size="large"
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          name={[field.name, 'required']}
          fieldKey={[field.fieldKey, 'required']}
          valuePropName="checked"
        >
          <Checkbox
            disabled={index <= 2}
          >
            Obligatoire
          </Checkbox>
        </Form.Item>
      </Col>
      <Col xs={10}>
        <Form.Item
          name={[field.name, 'type']}
          fieldKey={[field.fieldKey, 'type']}
        >
          <Select
            disabled={index <= 2}
            size="large"
            onChange={(value) => setFieldType(value)}
          >
            <Option value="text">
              <FontColorsOutlined/>
              {"  "}Text
            </Option>
            <Option value="number">
              <FieldNumberOutlined />
              {"  "}Chiffre
            </Option>
            <Option value="email">
              @
              {"  "}E-mail
            </Option>
            <Option value="phone">
              <PhoneOutlined />
              {"  "}Téléphone
            </Option>
            <Option value="select">
              <DownOutlined/>
              {"  "}Liste déroulante
            </Option>
            <Option value="checkbox">
              <CheckSquareOutlined/>
              {"  "}Choix multiples
            </Option>
            <Option value="radio">
              <AimOutlined/>
              {"  "}Choix unique
            </Option>
            <Option value="date">
              <CalendarOutlined/>
              {"  "}Date
            </Option>
            <Option value="time">
              <ClockCircleOutlined/>
              {"  "}Heure
            </Option>
          </Select>
        </Form.Item>
      </Col>
      {index > 2 && (
        <Col>
          <Tooltip title="Supprimer">
            <Button
              type="link"
              size={"large"}
              icon={<DeleteOutlined/>}
              onClick={() => remove(index)}
              danger
            />
          </Tooltip>
        </Col>
      )}
      {getField(fieldType)}
    </Row>
  );
}