import {gql} from "@apollo/client"

const INSERT_CUSTOMER = gql`
    mutation insertOneCustomer($data:CustomerInsertInput!){
        insertOneCustomer(data:$data){
            _id
            name
        }
    }
`

const UPDATE_CUSTOMER = gql`
    mutation updateOneCustomer($query: CustomerQueryInput, $set: CustomerUpdateInput!){
        updateOneCustomer(query:$query,set:$set){
            _id
            name
            contacts{
                name
                email
                phone
            }
        }
    }
`

const DISABLE_CUSTOMER = gql`
    mutation updateOneCustomer($query: CustomerQueryInput, $set: CustomerUpdateInput!){
        updateOneCustomer(query:$query,data:$data){
            _id
        }
    }
`
export {INSERT_CUSTOMER, UPDATE_CUSTOMER, DISABLE_CUSTOMER}