import React from "react"
import { MdExplore } from "react-icons/md";
import {
  ContactsOutlined,
  HomeOutlined,
  TeamOutlined,
  SettingOutlined,
  CalendarOutlined,
  CloudOutlined
} from "@ant-design/icons"
const routes = [
  // {
  //   label: "Accueil",
  //   href: '/home',
  //   icon: <MdExplore />
  // },
  // {
  //   label: "Prestataires",
  //   href: '/providers',
  //   icon: <CloudOutlined />
  // },
  {
    label: "Clients",
    href: '/customers',
    icon: <ContactsOutlined />
  },
  {
    label: "Evènements",
    href: '/events',
    icon: <CalendarOutlined />
  },
  // {
  //   label: "Utilisateurs",
  //   href: '/users',
  //   icon:<TeamOutlined />
  // },
  // {
  //   label: "Paramètres",
  //   href: '/settings',
  //   icon: <SettingOutlined />
  // },
]

export {routes}