const basicForm = [
  {
    type: "text",
    required:true,
    options: ["", ""],
    label: "Nom"
  },
  {
    type: "text",
    required:true,
    options: ["", ""],
    label: "Prénom"
  },
  {
    type: "email",
    required:true,
    options: ["", ""],
    label: "E-mail"
  },
]

const newOnlineLocation = {
  type: "online",
  name: "online",
  invitation: "open",
  form: [...basicForm],
  sessions: [],
  groups: []
}

const newPresentLocation = {
  type: "present",
  name: "Nouveau lieu",
  invitation: "open",
  form: [...basicForm],
  sessions: [],
  groups: []
}

const newHybridLocation = [
  {...newOnlineLocation},
  {...newPresentLocation}
]

const newFormItem = {
  type: "text",
  required: false,
  options: ["", ""],
  label: ""
}
export {
  basicForm,
  newOnlineLocation,
  newPresentLocation,
  newHybridLocation,
  newFormItem
}