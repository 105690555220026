import {Form, message} from "antd";
import {useUpsertOne} from "../CRUD/upsert";
import {UPSERT_FORM} from "../../apollo/mutations/forms";
import {FORM} from "../../apollo/queries/forms";
import {useLazyReadOne, useReadOne} from "../CRUD/read";
import {omit} from "lodash";

function useForm(_id) {
  
  const {form, loading, error} = useReadOne({
    query: FORM,
    variables: {query: {event: {_id}}},
    field: "form"
  })
  
  return {
    form,
    loading,
    error
  }
}

function useLazyForm(_id) {
  const {form, loading, error, called, lazyReadOne} = useLazyReadOne({
    query: FORM,
    variables: {query: {event: {_id}}},
    field: "form"
  })
  
  function loadForm() {
    lazyReadOne({variables: {query: {event: {_id}}}})
  }
  
  return {
    formFields: form ? form.fields.map(field => (field.label)) : [],
    loading,
    error,
    called,
    loadForm
  }
}

function useUpsertForm(cb) {
  const [form] = Form.useForm()
  const {upsertItem, loading} = useUpsertOne({
    mutation: UPSERT_FORM,
    update(cache, {data: {upsertedForm}}) {
      const {data} = cache.readQuery(
        {
          query: FORM,
          variables: {query: {event: {_id: upsertedForm.event._id}}},
        }
      )
      cache.writeQuery({
        query: FORM,
        variables: {query: {event: {_id: upsertedForm.event._id}}},
        data: {form: {...upsertedForm}}
      })
    }
  })
  
  function upsertForm(_id) {
    
    form.validateFields()
      .then(values => {
        upsertItem(
          {
            event: {
              link: _id
            },
            ...values,
            fields: values.fields.map(article => (omit(article, "__typename")))
          },
          {event: {_id}}
        ).then((data) => {
            cb()
            message.success("Le formulaire a bien créé", 2)
          })
          .catch(e => {
            console.log(e)
            message.error("Le formulaire n'a pas été créé!", 2)
          })
      })
  }
  
  return {
    form,
    upsertForm,
    loading
  }
}

export {useUpsertForm, useForm, useLazyForm}