import React from 'react';
import {Alert, Avatar, Button, Card, Col, Form, Input, Row} from "antd";
import {LockOutlined, UserOutlined,} from "@ant-design/icons";
import useLogin from "../hooks/login";

export default function Login() {
  const {handleLogin, error} = useLogin()
  
  
  function onFinish(values) {
    handleLogin(values.email, values.password)
  }
  
  return (
    <Row align="center" justify="middle" style={{height: "100vh"}}>
      <Col span={6}>
        <Card>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item style={{textAlign: "center"}}>
              <Avatar size={64} icon={<UserOutlined/>}/>
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Veuillez saisir un e-mail valide!",
                },
                {
                  required: true,
                  message: 'Veuillez saisir votre email!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon"/>}
                placeholder="Nom d'utilisateur"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre mot de passe!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon"/>}
                type="password"
                placeholder="Mot de passe"
              />
            </Form.Item>
            
            {error &&
            <Form.Item>
              <Alert
                message="Nom d'utilisateur ou mot de passe erroné"
                type="error"
              />
            </Form.Item>
            }
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
              >
                Connexion
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}