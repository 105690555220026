import {useLazyQuery, useQuery} from "@apollo/client";


function useReadOne(props) {
  const {variables, query, field, skip} = props
  const {data, loading, error, refetch} = useQuery(query, {variables, skip})
  return {
    [field]: data?.[field] ?? undefined,
    loading,
    error,
    refetch
  }
}

function useLazyReadOne(props) {
  const {variables, query, field} = props
  const [lazyReadOne, {
    data,
    loading,
    error,
    refetch,
    called
  }] = useLazyQuery(query, {variables})
  return {
    lazyReadOne,
    [field]: data?.[field] ?? undefined,
    loading,
    error,
    refetch,
    called
  }
}

function useReadMany(props) {
  const {variables, query, field,fetchPolicy} = props
  const {data, loading, error,refetch} = useQuery(query, {variables,fetchPolicy})
  return {
    [field]: data?.[field] ?? undefined,
    loading,
    error,
    refetch
  }
}

function useLazyReadMany(props) {
  const {variables, query, field} = props
  const [lazyReadMany, {
    data,
    loading,
    error,
    called
  }] = useLazyQuery(query, {variables})
  return {
    lazyReadMany,
    [field]: data?.[field] ?? [],
    loading,
    error,
    called
  }
}

export {useReadOne, useLazyReadOne, useReadMany, useLazyReadMany}