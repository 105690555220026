import {useMutation} from "@apollo/client";

function useUpsertOne(props) {
  const { mutation,update} = props
  const [upsertOne, {loading}] = useMutation(mutation,{update})
  function upsertItem(data,query,optimisticResponse) {
    return upsertOne({
      variables: {data,query},
      optimisticResponse
      // refetchQueries: refetch
    })
  }
  
  return {
    upsertItem,
    loading
  }
}

export {useUpsertOne}