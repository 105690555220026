import {useLazyReadMany, useReadMany} from "../CRUD/read";
import {CUSTOMERS} from "../../apollo/queries/customers";
import {useUpdateOne} from "../CRUD/update";
import {
  INSERT_CUSTOMER,
  UPDATE_CUSTOMER
} from "../../apollo/mutations/customers";
import {
  Button,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Skeleton,
  Tooltip
} from "antd"
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useInsertOne} from "../CRUD/insert";

function useCustomers() {
  const [form] = Form.useForm()
  const {customers, loading, error} = useReadMany({
    query: CUSTOMERS,
    variables: {},
    field: "customers"
  })
  
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const {
    updateItem,
    loading: updateLoading
  } = useUpdateOne({refetch: [CUSTOMERS], mutation: UPDATE_CUSTOMER})
  
  const {
    insertItem,
    loading: insertLoading
  } = useInsertOne(
    {
      //refetch: [CUSTOMERS],
      mutation: INSERT_CUSTOMER,
      update(cache, { data: { insertOneCustomer } }) {
        const { customers } = cache.readQuery({ query: CUSTOMERS });
        cache.writeQuery({
          query: CUSTOMERS,
          data: { customers: [...customers,insertOneCustomer] },
        });
      },
    })
  
  useEffect(() => {
    if (customers) {
      setFilteredCustomers(customers)
    }
  }, [customers]);
  
  
  function render(item) {
    return (
      <List.Item
        key={item._id}
        actions={
          item._id === "0" ?
            [
              <Tooltip title="Enregistrer">
                <Button
                  type="link"
                  onClick={saveNewCustomer}
                  loading={insertLoading}
                  icon={<CheckOutlined />}
                />
              </Tooltip>
              ,
              <Tooltip title="Annuler">
                <Button
                  type="link"
                  onClick={cancelAddCustomer}
                  icon={<CloseOutlined />}
                />
              </Tooltip>
            ]
            :
            [<Popconfirm
              title={"Etes-vous sûr de vouloir supprimer"}
              onConfirm={() => deleteCustomer(item._id)}
              okText="Oui"
              cancelText="Non"
            >
              <Button type="link" loading={updateLoading} icon={<DeleteOutlined />}/>
              
            </Popconfirm>
            ]
        }
      >
        {item._id === "0" ?
          <Form style={{width: "30%"}} form={form}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir le nom du client!",
                },
              ]}
            >
              <Input autoFocus placeholder="Nouveau client"/>
            </Form.Item>
          </Form>
          
          :
          <Skeleton title={false} active loading={false}>
            <List.Item.Meta
              title={<Link to={"/customer/" + item._id}>{item.name}</Link>}
            />
          </Skeleton>
        }
      </List.Item>
    )
  }
  
  function addNewCustomer() {
    setFilteredCustomers([{_id: "0"}, ...filteredCustomers])
  }
  
  function saveNewCustomer() {
    form.validateFields()
      .then(values => {
        cancelAddCustomer()
        const optimisticResponse={
          insertOneCustomer:{
            __typename:"Customer",
            name:values.name.trim(),
            _id:"tempId",
            id:"0"
          }
        }
        insertItem({name: values.name.trim()},optimisticResponse)
          .then(res => {
            message.success("Le client à bien été ajouté", 2);
            //cancelAddCustomer()
            form.resetFields()
          })
          .catch(e => {
            console.log(e)
            message.error("Le client n'a pas été ajouté", 2);
          })
      })
  }
  
  function cancelAddCustomer() {
    setFilteredCustomers(filteredCustomers.filter(customer => customer._id !== "0"))
  }
  
  function deleteCustomer(_id) {
    updateItem(_id, {deleted: true})
      .then(res => {
        message.success("Le client à bien été supprimé", 2);
      })
      .catch(e => {
        console.log(e)
        message.error("Le client n'a pas été supprimé", 2);
      })
  }
  
  function filter(e) {
    setTimeout(
      setFilteredCustomers(
        customers.filter((customer) => {
          return (
            customer.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
          );
        })
      )
    );
  }
  
  return {
    customers: filteredCustomers,
    loading,
    updateLoading,
    error,
    render,
    filter,
    addNewCustomer
  }
}

function useLazyCustomers() {
  const {
    lazyReadMany,
    loading,
    error,
    customers,
    called
  } = useLazyReadMany({field: "customers", variables: {}, query: CUSTOMERS})
  
  function loadCustomers() {
    if (!called) {
      lazyReadMany()
    }
  }
  
  return {
    loadCustomers,
    loading,
    error,
    customers
  }
}

export {useCustomers, useLazyCustomers}