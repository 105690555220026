import {gql} from "@apollo/client";

const FORM = gql`
    query form($query:FormQueryInput){
        form(query:$query){
            event{
                _id
            }
            fields{
                label
                options
                type
                required
            }
        }
    }
`

export {FORM}