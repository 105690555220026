import {EVENT, EVENTS} from "../../apollo/queries/events";
import {INSERT_EVENT, UPDATE_EVENT} from "../../apollo/mutations/events";
import {useRealmApp} from "../../RealmApp";
import {useReadOne} from "../CRUD/read";
import {useInsertOne} from "../CRUD/insert";
import {useUpdateOne} from "../CRUD/update";
import {Form, message} from "antd";
import React, {useState} from "react";
import {newEventId} from "../../apollo/variables";

function useEvent(_id) {
  const {event, loading, error} = useReadOne({
    query: EVENT,
    variables: {query: {_id}},
    field: "event"
  })
  return {
    event,
    loading,
    error
  }
}

function useInsertEvent(goNextStep) {
  const [infosForm] = Form.useForm()
  const {
    insertItem,
    loading
  } = useInsertOne({
    refetch: [EVENTS],
    mutation: INSERT_EVENT,
    update(cache, {data: {insertedEvent}}) {
      const data = cache.readQuery({query: EVENTS});
      newEventId(insertedEvent._id)
      cache.writeQuery({
        query: EVENT,
        variables: {query: {_id: insertedEvent._id}},
        data: {event: {...insertedEvent}}
      })
      if (data) {
        const {events} = data
        cache.writeQuery({
          query: EVENTS,
          data: {events: [...events, insertedEvent]},
        });
      }
    },
  })
  const app = useRealmApp()
  
  function insertEvent() {
    
    infosForm.validateFields()
      .then(values => {
        insertItem({
          title: values.title,
          description: values.description,
          nature: values.nature,
          start: values.date[0].toISOString(),
          end: values.date[1]?.toISOString(),
          state: "new",
          customer: {
            link: values.customer
          },
          provider: {link: app.currentUser.customData.provider.$oid},
          manager: {link: app.currentUser.customData._id},
        })
          .then((data) => {
            goNextStep()
            message.success("L'évènement a bien été créée", 2)
          })
          .catch(e => {
            console.log(e)
            message.error("L'évènement n'a pas été créée!", 2)
          })
      })
  }
  
  return {
    insertEvent,
    loading,
    infosForm,
  }
}

function useUpdateEventInfos() {
  const [infosForm] = Form.useForm()
  const [isEditable, setIsEditable] = useState(false);
  const {
    updateItem,
    loading
  } = useUpdateOne(
    {
      refetch: [EVENTS],
      mutation: UPDATE_EVENT,
      update(cache, {data: {updatedEvent}}) {
        const {event} = cache.readQuery({
          query: EVENT,
          variables: {query: {_id: updatedEvent._id}}
        })
        cache.writeQuery({
          query: EVENT,
          variables: {query: {_id: updatedEvent._id}},
          data: {event: {...event, ...updatedEvent}}
        })
      },
    })
  
  function updateEvent(_id) {
    infosForm.validateFields()
      .then(values => {
        updateItem(_id, {
          title: values.title,
          description: values.description,
          nature: values.nature,
          start: values.date[0].toISOString(),
          end: values.date[1]?.toISOString(),
          customer: {
            link: values.customer
          },
        })
          .then((data) => {
            setIsEditable(false)
            message.success("L'évènement a bien été mis-à-jour!", 2)
          })
          .catch(e => {
            console.log(e)
            message.error("L'évènement n'a pas été mis-à-jour!", 2)
          })
      })
  }
  
  return {
    updateEvent,
    loading,
    infosForm,
    isEditable,
    setIsEditable
  }
}

function useUpdateEvent() {
  const {
    updateItem,
    loading
  } = useUpdateOne(
    {
      refetch: [EVENTS],
      mutation: UPDATE_EVENT,
      update(cache, {data: {updatedEvent}}) {
        const {event} = cache.readQuery({
          query: EVENT,
          variables: {query: {_id: updatedEvent._id}}
        })
        cache.writeQuery({
          query: EVENT,
          variables: {query: {_id: updatedEvent._id}},
          data: {event: {...event, ...updatedEvent}}
        })
      },
    })
  
  function updateEvent(_id, update) {
    updateItem(_id, {
      ...update
    })
      .then((data) => {
        message.success("L'évènement a bien été mis-à-jour!", 2)
      })
      .catch(e => {
        console.log(e)
        message.error("L'évènement n'a pas été mis-à-jour!", 2)
      })
  }
  
  return {
    updateEvent,
    loading
  }
}

export {
  useEvent,
  useInsertEvent,
  useUpdateEventInfos,
  useUpdateEvent
}