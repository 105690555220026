import {useMutation} from "@apollo/client";

function useInsertOne(props) {
  const {refetch, mutation,update} = props
  const [insertOne, {loading}] = useMutation(mutation,{update})
  function insertItem(data,optimisticResponse) {
    return insertOne({
      variables: {data},
      optimisticResponse
     // refetchQueries: refetch
    })
  }
  
  return {
    insertItem,
    loading
  }
}

export {useInsertOne}