import React from 'react';
import DesktopLayout from "./desktop";
import Routes from "../components/routes";
import onScan from 'onscan.js'
onScan.attachTo(document);
export default function Index() {
  return (
    <DesktopLayout>
      <Routes/>
    </DesktopLayout>
  );
}