import React from 'react';
import {Button, DatePicker, Form, Input, InputNumber, List, Select} from "antd";
import {eventLocations} from "../../../../../../apollo/variables";
import _ from "lodash";
import {useReactiveVar} from "@apollo/client";
import {DeleteOutlined} from "@ant-design/icons";

export default function Group(props) {
  const {locationId, id} = props
  const watchLocations = useReactiveVar(eventLocations)
  const group = watchLocations[locationId].groups[id]
  const locationSessions = Object.values(watchLocations[locationId].sessions)
  const groupSessions = Object.values(group.sessions)
  
  function onChangeSessionsSelection(selection) {
    const newLocation = {...eventLocations()[locationId]}
    const selectedSessions = {}
    const locationSessions = {...newLocation.sessions}
    selection.forEach(session => {
      selectedSessions[session.value] = locationSessions[session.value]
      if (!session.repeat) {
        locationSessions[session.value].isSelected = true
      }
    })
    newLocation.groups[id] = {
      ...group,
      sessions: selectedSessions
    }
    const unselectedSessions = _.differenceBy(groupSessions, Object.values(selectedSessions), 'id')
    
    unselectedSessions.forEach(session => {
      if (!session.repeat) {
        locationSessions[session.id].isSelected = false
      }
    })
    newLocation.sessions = locationSessions
    eventLocations({...eventLocations(), [locationId]: newLocation})
  }
  
  function updateTitle(title) {
    const newLocation = {...eventLocations()[locationId]}
    newLocation.groups[id] = {
      ...group,
      title
    }
    eventLocations({...eventLocations(), [locationId]: newLocation})
  }
  
  function updateSession(sessionId, values) {
    const newLocation = {...eventLocations()[locationId]}
    const newSession = {
      ...newLocation.groups[id].sessions[sessionId],
      [values[0].name[0]]: values[0].value
    }
    newLocation.groups[id].sessions = {
      ...newLocation.groups[id].sessions,
      [sessionId]: newSession
    }
    eventLocations({...eventLocations(), [locationId]: newLocation})
  }
  
  function deleteSession(sessionId) {
    const newLocation = {...eventLocations()[locationId]}
    const newGroup = {
      ...group
    }
    delete newGroup.sessions[sessionId]
    newLocation.groups[id] = newGroup
    newLocation.sessions[sessionId].isSelected = false
    eventLocations({...eventLocations(), [locationId]: newLocation})
  }
  
  function render(item) {
    return (
      <Form
        layout="inline"
        onFieldsChange={(changes) => updateSession(item.id, changes)}
      >
        <Form.Item label="Session" style={{minWidth: 200, maxWidth: "100%"}}>
          <span>{item.title}</span>
        </Form.Item>
        <Form.Item label="date" name="date">
          <DatePicker
            style={{width: "100%"}}
            format="DD/MM/YYYY HH:mm"
            showTime={{format: 'HH:mm', minuteStep: 5}}
          />
        </Form.Item>
        <Form.Item label="capacité" name="capacity">
          <InputNumber style={{width: "100%"}}/>
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            size={"large"}
            icon={<DeleteOutlined/>}
            onClick={() => deleteSession(item.id)}
            danger
          />
        </Form.Item>
      </Form>
    )
  }
  
  return (
    <Form
      labelCol={{xs: 24, sm: 2}}
      initialValues={{...group}}
    >
      <Form.Item label="Titre" name="title">
        <Input onChange={(e) => setTimeout(() => updateTitle(e.target.value))}/>
      </Form.Item>
      <Form.Item label="sessions">
        <Select
          mode='multiple'
          placeholder='Selectionnez les categories de vos besoins'
          labelInValue={true}
          value={Object.values(group.sessions).map(s => ({
            label: s.title,
            value: s.id
          }))}
          onChange={onChangeSessionsSelection}
        >
          {_.differenceBy(locationSessions.filter(s => s.repetitive || !s.isSelected), groupSessions, 'id')
            .map(session => (
              <Select.Option key={session.id} value={session.id}>
                {session.title}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label=" ">
        <List
          dataSource={groupSessions}
          renderItem={render}
        />
      </Form.Item>
    </Form>
  )
}