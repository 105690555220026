import React, {useState} from 'react';
import {Button, Card, Steps, Typography} from "antd";
import Infos from "./event/infos";
import {useInsertEvent} from "../../../hooks/events/event";
import {RightOutlined} from "@ant-design/icons";
import EventForm from "./event/form/form";
import {useUpsertForm} from "../../../hooks/events/eventForm";
import {newEventId} from "../../../apollo/variables";
import {useHistory} from "react-router-dom";
import Access from "./createEvent/access/access";

const {Title} = Typography
const {Step} = Steps
export default function NewEvent() {
  const history = useHistory()
  const [step, setStep] = useState(0);
  const {insertEvent, infosForm} = useInsertEvent(goNextStep)
  const {
    upsertForm,
    form
  } = useUpsertForm(() => history.push("/event/" + newEventId()))
  const steps = [
    {
      title: "Informations",
      component:
        <Infos
          form={infosForm}
          isEditable={true}
        />
    },
    // {
    //   title: "Accès",
    //   component:
    //     <Access
    //       goNextStep={goNextStep}
    //       //goPreviousStep={goPreviousStep}
    //     />
    // },
    {
      title: "Inscriptions",
      component: <EventForm form={form}/>
    },
  ]
  
  function goNextStep() {
    setStep(step + 1)
  }
  
  function saveEvent() {
    if (step === 0) {
      insertEvent()
    } else {
      upsertForm(newEventId())
    }
  }
  
  return (
    <Card
      title={
        <Title level={3}>
          Nouvel Evènement
        </Title>
      }
      cover={
        <div style={{padding: 20}}>
          <Steps current={step}>
            {steps.map((step, i) =>
              (<Step title={step.title} key={step.title}/>))
            }
          </Steps>
        </div>
      }
      actions={[
        <Button size="large" type="link" onClick={saveEvent}>
          Enregistrer & Continuer
          <RightOutlined/>
        </Button>
      ]}
    >
      {steps[step].component}
    </Card>
  );
}